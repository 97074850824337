import "@babel/polyfill";

import { DOM } from "./DOM/DOM.js";
window.dom = new DOM;

import { isMobile, isTablet } from 'mobile-device-detect';


import { playVideoOnHover } from "./playVideoOnHover.js";

import { openMenu } from "./openMenu.js";

import { reviewsFancybox } from "./reviewsFancybox.js";

import { fixedSocials } from "./fixedSocials.js";

import { mainMenuSectionsNavigate } from "./mainMenuSectionsNavigate.js";

import { offersSlider } from "./offersSlider.js";

import { reviewsSlider } from "./reviewsSlider.js";

import { navPreventHideOnDropdown } from "./navPreventHideOnDropdown.js";

import { advantagesSlider } from "./advantagesSlider.js";

import { loadClass } from "./loadClass.js";

import { domClassOnView } from "./DOM/domClassOnView.js";

import { playFirstScreenVideo } from "./playFirstScreenVideo";

// import { firstScreenChange } from "./firstScreenChange.js";

import { scrollBody } from "./scrollBody.js";

import { GTM } from "./GTM";

import { domSlideLink } from "./DOM/domSlideLink.js";
import { domScrollTo } from "./DOM/domScrollTo.js";
import { domLikeLink } from "./DOM/domLikeLink.js";
import { domFiltering } from "./DOM/domFiltering.js";
import { domOverflowSlider } from "./DOM/domOverflowSlider.js";
import { domCustomScrollbar } from "./DOM/domCustomScrollbar.js";
import { domAccordion } from "./DOM/domAccordion.js";
import { domDropDown } from "./DOM/domDropDown.js";
import { DomFixedNav } from "./DOM/DomFixedNav.js";
import { domOpenModal } from "./DOM/domOpenModal.js";
import { domAjaxForm } from "./DOM/domAjaxForm.js";
import { domFormValidate } from "./DOM/domFormValidate.js";
import { domAjaxPagination } from "./DOM/domAjaxPagination.js";
import { domAbsoluteFix } from "./DOM/domAbsoluteFix.js";
import { DomTabs } from "./DOM/DomTabs.js";
import { domScrollDrag } from "./DOM/domScrollDrag.js";
import { domCarousel } from "./DOM/domCarousel.js";
import { domImageCover } from "./DOM/domImageCover.js";
import { domLazyLoad } from "./DOM/domLazyLoad.js";
// import { domCounterChars } from "./DOM/domCounterChars";
import { DomCountOnScroll } from "./DOM/DomCountOnScroll.js";

// import { ajaxVideo } from "./ajaxVideo";


import "../css/main.less";


const detectDevise = () => {
  let className = 'device-desktop';

  if (isMobile) {
    className = 'device-mobile';
  }

  if (isTablet) {
    className = 'device-tablet';
  }
  document.body.classList.add(className);
}



export const dinamicScripts = container => {

  detectDevise();
  domClassOnView();

  // domCounterChars(container);
  new DomCountOnScroll({ calcAuto: true, transition: 1000, after: (item) => item.parentNode.classList.add('nums-loaded') }, container);

  domCustomScrollbar(container);
  playVideoOnHover(container);
  reviewsFancybox();
  domSlideLink(container);
  domAccordion(container);
  domDropDown(container);
  domOpenModal(container);
  domFormValidate(container);
  domAjaxPagination(container, {
    after: content => {
      if (dom.body.classList.contains('browser-ie')) {
        domImageCover(content);
      }
    }
  });

  domAbsoluteFix(container);
  new DomTabs(container);
  domScrollDrag(container);
  domCarousel(container);
  domScrollTo(container);
  domLikeLink(container);
  advantagesSlider(container);

  domOverflowSlider(container);

  domFiltering(container);

  offersSlider(container);

  reviewsSlider(container);

  loadClass(container);

  // ajaxVideo(container);

  if (dom.body.classList.contains('browser-ie')) {
    domImageCover(container);
  }
  domAjaxForm(container);
  // rellaxBackgrounds(container);
  new domLazyLoad(container);

  playFirstScreenVideo(container);

  GTM(container);
};





export const staticScripts = () => {
  // firstScreenChange();
  // scrollBody();
  AOS.init({
    // Global settings:
    disable: ['phone', 'tablet'], // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 10, // offset (in px) from the original trigger point
    delay: 500, // values from 0 to 3000, with step 50ms
    duration: 700, // values from 0 to 3000, with step 50ms
    easing: 'cubic-bezier(.07,.51,.12,1)', // default easing for AOS animations  cubic-bezier(.07,.51,.12,1)
    once: true, // whether animation should happen only once - while scrolling down
    mirror: true, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

  });
  openMenu();
  fixedSocials();
  new DomFixedNav({
    nav: '.fixed-nav',
    offsetHide: '80vh',
    hiddenClass: 'nav-is-hidden',
    scrollClass: 'nav-in-scroll'
  });
  navPreventHideOnDropdown();
  mainMenuSectionsNavigate();

  // scrollBody();
};




dinamicScripts();
staticScripts();