const THEME_CLASS = {
  'dark': 'dark-theme',
  'light': 'light-theme'
};


const DEFAULT_THEME_KEY = 'dark';




export const fixedSocials = () => {
  let btn = dom.findFirst('.open-social-btn');
  if (!btn) return;
  let closeBtn = dom.findFirst('.close-social-btn');

  let wrap = dom.findFirst('#fixedSocials');
  if (!wrap) return;
  let container = dom.findFirst('.fixed-socials');


  if (document.readyState === 'interactive') {
    init({ btn, closeBtn, wrap, container });
  } else {
    document.addEventListener('DOMContentLoaded', e => {
      init({ btn, closeBtn, wrap, container });
    });
  }

  initObserve({ btn, wrap });
}


const initObserve = ({ btn, wrap }) => {
  let parent = wrap.parentNode;
  let observer = new MutationObserver(mutations => {
    if (btn.classList.contains('active')) {
      dom.addClass(parent, 'active');
    } else {
      dom.removeClass(parent, 'active');
    }
  });
  observer.observe(btn, { attributes: true });
}



const init = ({ btn, closeBtn, wrap, container }) => {
  initCloseBtn(closeBtn, wrap);

  btn.addEventListener('click', e => {
    e.preventDefault();
    e.stopPropagation();
    toggleSocials(btn, wrap);
  });

  dom.body.addEventListener('click', e => {
    dom.removeClass(btn, 'active');
    dom.slideUp(wrap);
  });


  let sections = getThemeSections();
  let theme = getCurrentTheme(container, sections);
  setTheme(container, theme);

  let footer = dom.findFirst('.footer');

  ifHideSocials(footer, container);
  window.addEventListener('scroll', e => {
    ifHideSocials(footer, container);
    let currentTheme = getCurrentTheme(container, sections);

    if (currentTheme && currentTheme !== theme) {
      theme = currentTheme;
      setTheme(container, theme);
    }
  });

  let resizeTimer = null;
  window.addEventListener('resize', e => {
    if (resizeTimer) clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      sections = getThemeSections();
    }, 20);
  })
}





const initCloseBtn = (btn, wrap) => {
  if (!btn) return;
  btn.addEventListener('click', e => {
    e.preventDefault();
  });
}





const ifHideSocials = (footer, container) => {
  if (!footer) return false;
  let top = footer.getBoundingClientRect().top + window.pageYOffset;
  if (top <= window.pageYOffset + window.innerHeight) {
    dom.addClass(container, 'is-hidden');
  } else {
    dom.removeClass(container, 'is-hidden');
  }
}





const toggleSocials = (btn, wrap, isCloseBtn = false) => {
  let slide = dom.toggleSlide(wrap);
  if (slide === 'slideDown') {
    dom.addClass(btn, 'active');
  } else {
    dom.removeClass(btn, 'active');
  }
}





const getThemeSections = () => {
  let sections = dom.findAll('[data-theme]');
  if (!sections || !sections.length) return false;
  let res = [];
  sections.forEach(item => {
    let top = item.getBoundingClientRect().top + window.pageYOffset;
    res.push({
      top: top,
      bottom: top + item.offsetHeight,
      theme: item.dataset.theme
    });
  });
  return res;
}






const getCurrentTheme = (container, sections) => {
  let top = container.getBoundingClientRect().top + window.pageYOffset + container.offsetHeight / 2;
  let total = sections.length;
  for (let i = 0; i < total; i++) {
    let item = sections[i];
    if (top > item.top && top < item.bottom) {
      return item.theme;
    }
  }
  return DEFAULT_THEME_KEY;
}





const setTheme = (container, theme) => {
  Object.keys(THEME_CLASS).forEach(key => {
    dom.removeClass(container, THEME_CLASS[key]);
  });
  theme = theme ? theme : DEFAULT_THEME_KEY;
  dom.addClass(container, THEME_CLASS[theme]);
}