import { React } from "./domReact.js";

const BTN_CLASS = 'dom-open-modal';
const BTN_ACTIVE_CLASS = 'active';
const MODAL_ACTIVE_CLASS = 'active';
const BODY_CLASS = 'dom-modal-is-open';


export const domOpenModal = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  let btns = dom.findAll(`.${BTN_CLASS}`, container);

  if (!btns || !btns.length) return;

  btns.forEach(btn => {
    let id = btn.dataset.target;
    let target = dom.findFirst(id);
    if (!target) return;
    if (btn.hasAttribute('data-hash')) {
      checkHash(btn, target, id);
    }
    btn.addEventListener('click', e => {
      e.preventDefault();
      if (btn.hasAttribute('data-hash')) {
        window.location.hash = id;
      }
      initBtn(btn, target);
    });
  });
}


const checkHash = (btn, target, id) => {
  if (window.location.hash !== id) return;
  initBtn(btn, target);
}



const initBtn = (btn, target) => {
  if (!btn.classList.contains(BTN_ACTIVE_CLASS)) {
    showModal(btn, target);
  } else {
    hideModal(btn, target);
  }
}




const showModal = (btn, target) => {
  dom.dispatch(dom.body, 'stop_hide_menu');
  let close = dom.findFirst('.close-modal-btn', target);

  if (!close) {
    let closeBtn = createCloseBtn();
    target.appendChild(closeBtn);
    close = dom.findFirst('.close-modal-btn', target);
    initCloseModal(close, btn, target);
  }

  dom.bodyOverflowHidden();
  if (dom.hasDomAttr(btn, 'body-class')) {
    dom.addClass(dom.body, dom.getDomAttr(btn, 'body-class'));
  }
  dom.addClass(dom.body, BODY_CLASS);
  dom.addClass(btn, BTN_ACTIVE_CLASS);
  dom.addClass(target, MODAL_ACTIVE_CLASS);
}




const hideModal = (btn, target) => {
  dom.dispatch(dom.body, 'start_hide_menu');
  dom.bodyOverflowAuto();
  if (dom.hasDomAttr(btn, 'body-class')) {
    dom.removeClass(dom.body, dom.getDomAttr(btn, 'body-class'));
  }
  dom.removeClass(dom.body, BODY_CLASS);
  dom.removeClass(btn, BTN_ACTIVE_CLASS);
  dom.removeClass(target, MODAL_ACTIVE_CLASS);
}





const initCloseModal = (close, btn, target) => {
  close.addEventListener('click', e => {
    e.preventDefault();
    if (window.location.hash === btn.dataset.target) {
      // window.location.hash = '';
      history.pushState("", document.title, window.location.pathname);
    }
    hideModal(btn, target);
  });
}





const createCloseBtn = () => {
  return (
    <div class="hamburger hamburger--spin js-hamburger open-menu-btn is-active close-modal-btn">
      <div class="hamburger-box">
        <div class="hamburger-inner"></div>
      </div>
    </div>
  );
  return (
    <div className="close-modal-btn">
      <a href="#" class="close-button">
        <div class="in">
          <div class="close-button-block"></div>
          <div class="close-button-block"></div>
        </div>
        <div class="out">
          <div class="close-button-block"></div>
          <div class="close-button-block"></div>
        </div>
      </a>
    </div>
  );
}