const BTN_ACTIVE_CLASS  = 'is-active';
const MENU_ACTIVE_CLASS = 'active';
const BODY_CLASS = 'nav-is-open';

const CLOSE_EVENT = 'close-offcanvas';


export const openMenu = () => {
  let btn = dom.findFirst('.open-menu-btn');
  if ( !btn ) return;
  let menu = dom.findFirst('.offcanvas-nav');
  if ( !menu ) return;
  initOpenMenu(btn, menu);

  initCloseOnEvent(btn, menu);
}





const initCloseOnEvent = (btn, menu) => {
  dom.body.addEventListener(CLOSE_EVENT, e => {
    closeMenu(btn, menu);
  });
}



const initOpenMenu = (btn, menu) => {
  btn.addEventListener('click', e => {
    e.preventDefault();
    if (menu.classList.contains(MENU_ACTIVE_CLASS)) {
      closeMenu(btn, menu);
    } else {
      openOffcanvasMenu(btn, menu);
    }
  });
}





const closeMenu = (btn, menu) => {
  if (!menu.classList.contains(MENU_ACTIVE_CLASS)) return;
  dom.removeClass(dom.body, BODY_CLASS);
  dom.dispatch(dom.body, 'start_hide_menu');
  dom.bodyOverflowAuto();
  dom.removeClass(btn, BTN_ACTIVE_CLASS);
  dom.removeClass(menu, MENU_ACTIVE_CLASS);
}




const openOffcanvasMenu = (btn, menu) => {
  if (menu.classList.contains(MENU_ACTIVE_CLASS)) return;
  dom.addClass(dom.body, BODY_CLASS);
  dom.dispatch(dom.body, 'stop_hide_menu');
  dom.bodyOverflowHidden();
  dom.addClass(btn, BTN_ACTIVE_CLASS);
  dom.addClass(menu, MENU_ACTIVE_CLASS);
}