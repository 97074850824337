import { DOM } from './DOM';



export class DomCountOnScroll extends DOM {


  constructor(sets = {}, container) {
    super();

    this.container = this.getContainer(container);

    if (!this.container) return;

    if (!this.getElements()) return;
    this.inteval = parseInt(sets.interval) || 100;
    this.margin = parseInt(sets.margin) || -100;
    this.max = parseInt(sets.max);
    this.step = parseInt(sets.step) || 1;
    this.calcAuto = sets.calcAuto;
    this.transition = sets.transition || 3000;
    this.after = sets.after;


    this.disabled = [];
    this.intervals = [];

    this.setElements();
    this.init();
  }



  getElements() {
    this.items = this.findAll('.count-on-scroll', this.container);
    if (!this.items || !this.items.length) return;

    return true;
  }





  setElements() {
    this.items.forEach((item, i) => {
      this.setItem(item, i);
    });
  }




  init() {
    let timer;
    window.addEventListener('scroll', e => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.items.forEach((item, i) => {
          this.setItem(item, i);
        });
      }, 20);

    });

  }





  setItem(item, i) {
    if (item.classList.contains('start-count') || !this.isInViewport(item)) return;
    this.addClass(item, 'start-count');
    this.initNumbers(item, i);
  }



  initNumbers(item, i) {
    let max = parseInt(item.dataset.max) || this.max;
    if (!max) return;

    let step = this.calcStep(max);

    this.initAnimation(item, max, step, 0);

  }


  initAnimation(item, max, step, current) {
    if (current === max) {
      this.addClass(item.parentNode, 'nums-loaded');
      return;
    }
    window.requestAnimationFrame(() => {
      current = current + step;
      current = current > max ? max : current;

      item.innerHTML = current;
      this.initAnimation(item, max, step, current);
    });
  }



  calcStep(max) {
    let iterations = Math.ceil(this.transition / 14);
    let step = Math.ceil(max / iterations);
    return step;
  }

}
