const HIDE_EVENT = 'hide-fixed-nav';

import { DOM } from './DOM.js';

export class DomFixedNav extends DOM {


  constructor(sets) {
    super();
    this.nav = this.findFirst(sets.nav || 'nav');
    if (!this.nav) return;

    this.offsetHide = this.getOffsetHide(sets.offsetHide);
    this.offsetScroll = sets.offsetScroll || 0;
    this.hiddenClass = sets.hiddenClass || 'nav-is-hidden';
    this.scrollClass = sets.scrollClass || 'nav-in-scroll';
    this.visible = true;
    this.scroll = false;

    this.initStopOnOfcanvasOpen();
    this.setCurrentState();
    this.init();
    this.hideOnEvent();

    this.showOnMouseMove();
  }




  showOnMouseMove() {
    window.addEventListener('mousemove', e => {
      if (e.clientY < 15) {
        this.showMenu();
        this.showOnMove = true;
      }
    });
    // this.nav.addEventListener('mouseleave', e => {
    //   if (this.showOnMove) {
    //     this.hideMenu();
    //     this.showOnMove = false;
    //   }
    // });
  }





  hideOnEvent() {
    this.body.addEventListener(HIDE_EVENT, e => {
      this.hideMenu();
    });
  }




  getOffsetHide(set) {
    if (!set) return 150;
    if (set.indexOf('vh') !== false) {
      let size = parseInt(set);
      return size * window.innerHeight / 100;
    }
    return set;
  }




  init() {
    window.addEventListener('scroll', this.setScrollOnScroll.bind(this));
    let countUpScroll = 0;
    let countDownScroll = 0;
    let lastScrollTop = 0;

    window.addEventListener('scroll', e => {

      let st = window.pageYOffset || document.documentElement.scrollTop;

      if (st > lastScrollTop) {
        countUpScroll = 0;
        countDownScroll++;
      } else {
        countDownScroll = 0;
        countUpScroll++;
      }
      lastScrollTop = st <= 0 ? 0 : st;
      if (countUpScroll > 5 || countDownScroll > 5) {
        this.hideNavOnScroll();
        countUpScroll = 0;
        countDownScroll = 0;
      }

    });

  }






  initStopOnOfcanvasOpen() {
    document.body.addEventListener('stop_hide_menu', () => {
      this.stop = true;
    });
    document.body.addEventListener('start_hide_menu', () => {
      this.stop = false;
    });
  }


  setScrollOnScroll() {

    if (this.scrollEventSet) {
      clearTimeout(this.scrollEventSet);
    }

    this.scrollEventSet = setTimeout(() => {
      if (window.pageYOffset > this.offsetScroll && !this.scroll) this.setScrollMenu();
      if (window.pageYOffset <= this.offsetScroll && this.scroll) this.unsetScrollMenu();
    }, 20);

  }




  hideNavOnScroll() {
    if (this.scrollEventHide) {
      clearTimeout(this.scrollEventHide);
    }

    this.scrollEventHide = setTimeout(() => {
      if (this.stop) return;

      if (window.pageYOffset <= this.offsetHide && !this.visible) {
        this.showMenu();
      }

      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (window.pageYOffset > this.offsetHide) {
        if (st > this.lastScrollTop) {
          if (this.visible) {
            // let $btn = $('.open-contacts-dropdown');
            // if ($btn.hasClass('active')) return;
            this.hideMenu();
          }
        } else {
          if (!this.visible) {
            this.showMenu();
          }
        }
      }
      this.lastScrollTop = st <= 0 ? 0 : st;
    }, 20);

  }





  setCurrentState() {
    if (window.pageYOffset > this.offsetHide) {
      this.hideMenu();
    }
    if (window.pageYOffset > this.offsetScroll) {
      this.setScrollMenu();
    }
  }


  hideMenu() {
    if (window.pageYOffset <= this.offsetHide || this.stop) return;
    this.visible = false;
    this.addClass(this.nav, this.hiddenClass);
  }



  showMenu() {
    this.visible = true;
    this.removeClass(this.nav, this.hiddenClass);
  }


  setScrollMenu() {
    this.scroll = true;
    this.addClass(this.nav, this.scrollClass);
  }

  unsetScrollMenu() {
    this.scroll = false;
    this.removeClass(this.nav, this.scrollClass);
  }

}
