import "./css/scrollBody.less";

// const EL_CLASS = 'site-wrap';
const EL_CLASS = 'main';

const TRANSITION = 600;

let EL, SCROLL_VIEW;

let header, next;


let didHeaderAnimation = false;
let headerAnimation = false;

export const scrollBody = () => {
  if (!document.body.classList.contains('device-desktop') || document.body.classList.contains('browser-ie')) return;
  EL = dom.findFirst(`.${EL_CLASS}`);
  if (!EL) return;

  header = dom.findFirst('.front-header');
  if (!header) return;
  if (header) {
    next = dom.next(header);
    if (!next) return;
  }

  dom.css(EL, { 'padding-top': header.offsetHeight + 'px' });

  createScrollView();

  dom.addClass(EL, 'dom-fixed-scroll');


  let scrollTimer = false;

  let countHeaderScroll = 0;

  window.addEventListener('scroll', e => {
    if (scrollTimer) clearTimeout(scrollTimer);

    scrollTimer = setTimeout(() => {
      if (window.pageYOffset > header.offsetHeight) {
        didHeaderAnimation = true;
        restore();
        return;
      }
      let scroll = window.pageYOffset;
      let headerEnd = header ? header.offsetHeight : false;

      if (scroll > headerEnd) {
        didHeaderAnimation = true;
      }

      if (!didHeaderAnimation) {

        window.scrollTo(0, 0);

        if (header && scroll < headerEnd && countHeaderScroll < 6) {
          countHeaderScroll++;
          return;
        }

        if (header && scroll < headerEnd && countHeaderScroll > 5) {
          didHeaderAnimation = true;
          headerAnimation = true;
          dom.addClass(header, 'hide-header');
          setTimeout(() => {
            restore();
            window.scrollTo(0, header.offsetHeight);
          }, TRANSITION);
        }
      }

      if (headerAnimation) {
        return;
      }


    }, 5);


  });


  let resizeTimer = false;
  window.addEventListener('resize', e => {
    if (resizeTimer) clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      setScrollViewHeight();
    }, 5);
  })
}



const restore = () => {
  dom.removeClass(EL, 'dom-fixed-scroll');
  removeScrollView();
  dom.removeClass(header, 'hide-header');
  dom.css(header, { position: 'relative' });
  EL.removeAttribute('style');
  dom.addClass(next, 'visible');
  headerAnimation = false;
}





const createScrollView = () => {
  SCROLL_VIEW = dom.create('div', 'scroll-view');
  setScrollViewHeight();
  document.body.appendChild(SCROLL_VIEW);
}

const setScrollViewHeight = () => {
  dom.css(SCROLL_VIEW, { height: EL.offsetHeight + 'px' });
}


const removeScrollView = () => {
  if (SCROLL_VIEW && SCROLL_VIEW.parentNode) {
    SCROLL_VIEW.parentNode.removeChild(SCROLL_VIEW);
  }
}



