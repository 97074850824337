export const playFirstScreenVideo = (wrap) => {
  let container = dom.getContainer(wrap);
  let video = dom.findFirst('.front-header-video', container);
  if (!video) return;
  if (document.body.classList.contains('device-mobile')) {
    video.parentNode.removeChild(video);
  } else {
    dom.addClass(video.parentNode, 'play-video');
    video.play();
  }
}