import { initGtmUnsuccessfulSubmit, initGtmSendForm } from "../GTM";

const VALIDATE = true;

const FORM_CLASS = 'form-validate';

const INPUT_CLASS = 'input';

const INPUT_ERROR_CLASS = 'has-error';

const HELP_CLASS = 'input-help';

const HELP_ERROR_CLASS = 'error';

const CUSTOM_INPUTS_TYPE = ['checkbox'];

let HAS_MESSAGE = false;


let error_messages = [];


export const domFormValidate = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  let forms = dom.findAll(`.${FORM_CLASS}`, container);
  if (!forms || !forms.length) return;
  forms.forEach(form => {
    initValidationForm(form);
  });
}




const getHelp = input => {
  if (CUSTOM_INPUTS_TYPE.indexOf(input.type) > -1) {
    return input.parentNode.parentNode.querySelector(`.${HELP_CLASS}`);
  } else {
    return input.parentNode.querySelector(`.${HELP_CLASS}`);
  }
}



const createHelp = () => {
  let div = document.createElement('div');
  div.classList.add(HELP_CLASS);
  return div;
}






const initValidationForm = form => {
  let inputs = dom.findAll(INPUT_CLASS, form);
  if (!inputs || !inputs.length) return;

  let submitBtn = dom.findFirst('[type=submit]', form);
  if (!submitBtn) return;

  let helpContainer = form.dataset.helpContainer;
  if (helpContainer) {
    helpContainer = dom.findFirst(helpContainer, form);
  }

  inputs.forEach(input => {
    if (!helpContainer) {
      addHelp(input);
    }
    initRemoveInputError(input);
  });
  initValidation(form, inputs, submitBtn, helpContainer);
}




const addHelp = input => {
  let help = getHelp(input);
  if (!help) {
    help = createHelp();
    if (CUSTOM_INPUTS_TYPE.indexOf(input.type) > -1) {
      input.parentNode.parentNode.insertBefore(help, input.parentNode.nextSibling);
    } else {
      input.parentNode.insertBefore(help, input.nextSibling);
    }
  }
}





const initValidation = (form, inputs, submitBtn, helpContainer) => {
  submitBtn.addEventListener('click', e => {
    e.preventDefault();
    initGtmSendForm();
    hideHelpContainer(helpContainer);
    if (validateForm(inputs, helpContainer, form) || !VALIDATE) {
      dom.dispatch(form, 'submit');
    }
  });
}






export const hideHelpContainer = helpContainer => {
  if (!helpContainer) return;
  dom.removeClass(helpContainer, 'visible success');
  dom.addClass(helpContainer, 'hidden');
  let wrap = getHelpContainerMsgWrap(helpContainer);
  wrap.innerHTML = '';
  HAS_MESSAGE = false;
}



export const showHelpContainer = helpContainer => {
  dom.removeClass(helpContainer, 'hidden');
  dom.addClass(helpContainer, 'visible');
}




const validateForm = (inputs, helpContainer, form) => {
  let errors = [];

  error_messages = [];

  inputs.forEach(input => {
    errors.push(!validateInput(input, helpContainer));
  });

  if (HAS_MESSAGE && helpContainer) {
    showHelpContainer(helpContainer);
  }

  let isValid = !errors.some(item => item);

  if (!isValid) {
    initGtmUnsuccessfulSubmit({ errors: error_messages });
    scrollToTopForm(form);
  }

  return isValid;
}



const scrollToTopForm = form => {
  window.scrollTo(0, form.getBoundingClientRect().top + window.pageYOffset - 100);
}




const validateInput = (input, helpContainer) => {

  if (input.hasAttribute('required')) {
    if (!validateRequired(input, helpContainer)) return false;
  }


  if (input.dataset.min) {
    if (!validateMin(input, helpContainer)) return false;
  }

  if (input.dataset.max) {
    if (!validateMax(input, helpContainer)) return false;
  }

  if (input.dataset.regex) {
    if (!validateRegex(input, helpContainer)) return false;
  }

  if (input.dataset.regexMatch) {
    if (!validateRegexInvert(input, helpContainer)) return false;
  }

  if (input.type === 'email' && input.value) {
    if (!validateEmail(input, helpContainer)) return false;
  }

  if (input.dataset.passwordCompare) {
    if (!validatePasswordCompare(input, helpContainer)) return false;
  }

  if (input.dataset.diff) {
    if (!validateDiff(input, helpContainer)) return false;
  }

  if (input.dataset.website === 'true') {
    if (!validateWebsite(input, helpContainer)) return false;
  }

  return true;
}





const initRemoveInputError = input => {
  input.addEventListener('change', e => {
    dom.removeClass(input, INPUT_ERROR_CLASS);
    let help = getHelp(input);
    if (help) {
      dom.removeClass(help, HELP_ERROR_CLASS);
      help.innerHTML = '';
    }
  });
}







const validateRequired = (input, helpContainer) => {
  if (input.type === 'checkbox') {
    if (!input.checked) {
      addInputError(input, 'required', helpContainer);
      return false;
    }
    return true;
  }
  if (!input.hasAttribute('required') && !input.value) return;
  if (!input.value) {
    addInputError(input, 'required', helpContainer);
    return false;
  }
  return true;
}




const validateEmail = (input, helpContainer) => {

  if (!input.hasAttribute('required') && !input.value) return;

  if (input.value.search(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === -1) {
    addInputError(input, 'mail_format', helpContainer);
    return false;
  }
  let mailParts = input.value.split('@');

  if (mailParts.length < 2) {
    addInputError(input, 'mail_format', helpContainer);
    return false;
  }

  if (mailParts[0].length > 63 || mailParts[1].length > 253) {
    addInputError(input, 'mail_format', helpContainer, input.dataset.maxMsg ? input.dataset.maxMsg : null);
    return false;
  }

  if (/오오|wgnФ|xxx\.xx|test\.te/.test(mailParts[1])) {
    addInputError(input, 'mail_format', helpContainer, input.dataset.domainErrorMsg ? input.dataset.domainErrorMsg : null);
  }

  return true;
}





const validateMin = (input, helpContainer) => {
  if (!input.hasAttribute('required') && !input.value) return;

  let min = parseFloat(input.dataset.min);
  if (input.type === 'number') {
    if (parseFloat(input.value) < min) {
      addInputError(input, 'min_number', helpContainer, input.dataset.minMsg ? input.dataset.minMsg : null);
      return false;
    }
  }

  if (input.type === 'text' || input.type === 'password' || input.type === 'email') {
    if (parseInt(input.value.replace(/[\s]+/g, '').length) < parseInt(min)) {
      addInputError(input, 'min_str', helpContainer, input.dataset.minMsg ? input.dataset.minMsg : null);
      return false;
    }
  }

  return true;
}



const validateMax = (input, helpContainer) => {
  if (!input.hasAttribute('required') && !input.value) return;
  let max = parseFloat(input.dataset.max);
  if (input.type === 'number') {
    if (parseFloat(input.value) > max) {
      addInputError(input, 'max_number', helpContainer, input.dataset.maxMsg ? input.dataset.maxMsg : null);
      return false;
    }
  }

  if (input.type === 'text' || input.type === 'password' || input.type === 'email') {
    if (parseInt(input.value.length) > parseInt(max)) {
      addInputError(input, 'max_str', helpContainer, input.dataset.maxMsg ? input.dataset.maxMsg : null);
      return false;
    }
  }

  return true;
}





const validateRegex = (input, helpContainer) => {
  if (!input.hasAttribute('required') && !input.value) return;
  let reg = new RegExp(input.dataset.regex, 'g');
  if (reg.test(input.value)) {
    addInputError(input, 'not_allowed_symbols', helpContainer, input.dataset.regexMsg ? input.dataset.regexMsg : null);
    return false;
  }
  return true;
}



const validateRegexInvert = (input, helpContainer) => {
  if (!input.hasAttribute('required') && !input.value) return;
  let reg = new RegExp(input.dataset.regexMatch, 'g');
  if (!input.value.match(reg)) {
    addInputError(input, 'not_allowed_symbols', helpContainer, input.dataset.regexMsg ? input.dataset.regexMsg : null);
    return false;
  }
  return true;
}





const validatePasswordCompare = (input, helpContainer) => {
  let compare = dom.findFirst(input.dataset.passwordCompare);
  if (!compare) return true;
  if (input.value !== compare.value) {
    addInputError(input, 'compare_password', helpContainer);
    addInputError(compare, 'compare_password', helpContainer);
    return false;
  }
  return true;
}





const validateDiff = (input, helpContainer) => {
  if (!input.hasAttribute('required') && !input.value) return;

  let diff = JSON.parse(input.dataset.diff);

  let messages = false;

  if (input.dataset.diffMsg) {
    messages = JSON.parse(input.dataset.diffMsg);
  }

  for (let i = 0; i < diff.length; i++) {
    let sel = diff[i];
    let diffInput = dom.findFirst(sel);
    if (!diffInput) continue;

    if (input.value === diffInput.value) {
      addInputError(input, 'diff_value', helpContainer, messages ? messages[i] : false);
      return false;
    }
  }

  return true;
}





const validateWebsite = (input, helpContainer) => {
  if (!input.hasAttribute('required') && !input.value) return;

  if (!input.value.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)) {
    addInputError(input, 'website_invalid', helpContainer, input.dataset.websiteMsg);
    return false;
  }
  return true;
}




const replaceStrArgs = (str, args = []) => {
  for (let i = 0; i < args.length; i++) {
    str = str.replace(`$${i + 1}`, args[i]);
  }
  return str;
}



export const addInputError = (input, msgKey, helpContainer, message) => {
  dom.addClass(input, INPUT_ERROR_CLASS);
  if (input.dataset.acceptTerms) {
    msgKey = 'accept_terms';
  }

  let msg;

  if (msgKey !== 'ajax_response' && !message) {
    msg = window.form_validate_msg[msgKey];
    if (msgKey === 'min_number' || msgKey === 'min_str') msg = replaceStrArgs(ms, g[input.dataset.min]);
    if (msgKey === 'max_number' || msgKey === 'max_str') msg = replaceStrArgs(msg, [input.dataset.max]);
  }

  if (message) {
    msg = message;
  }

  if (!msg) return;

  if (!helpContainer) {
    let help = getHelp(input);
    dom.addClass(help, HELP_ERROR_CLASS);
    help.innerHTML = msg;
  } else {
    let wrap = getHelpContainerMsgWrap(helpContainer);
    dom.removeClass(wrap, 'visible success');
    if (!dom.findFirst(`.${msgKey}`, wrap)) {
      let row = dom.create('div', 'valid-msg ' + msgKey);
      row.innerHTML = msg;
      wrap.appendChild(row);
      HAS_MESSAGE = true;
      error_messages.push(msg);
    }

  }
}





export const getHelpContainerMsgWrap = helpContainer => {
  let wrap = dom.findFirst('.msg-wrapper', helpContainer);
  if (!wrap) {
    wrap = createHelpWrapContainer();
    helpContainer.appendChild(wrap);
  }
  return wrap;
}





const createHelpWrapContainer = () => {
  return dom.create('div', 'msg-wrapper');
}