import { initGtmOutboundLink } from "./GTM";

const DISPLAY_SLIDERS = 3;
const START_BREAKPOINT = 768;


let container, sliders, section, row;

let arrows, nextArrow, prevArrow;

let currentLast = false, currentSliders = [];

let animationTimer;


export const offersSlider = wrap => {
  container = dom.getContainer(wrap);
  if (!container) return;
  section = dom.findFirst('.section-offers', container);
  if (!section) return;

  row = dom.findFirst('.offers-row', section);
  if (!row) return;
  sliders = dom.findAll('.offer-col', row);
  if (!sliders || !sliders.length) return;

  let maxSliders = getMaxSliders();
  if (sliders.length <= maxSliders) {
    showAllSliders();
    return;
  };


  arrows = dom.findFirst('.arrows-wrap', section);
  if (!arrows) return;

  prevArrow = dom.findFirst('.arrow-prev', arrows);
  nextArrow = dom.findFirst('.arrow-next', arrows);

  dom.addClass(row, 'init');
  initOnResize();
  init();
}






const initOnResize = () => {
  let timer;
  window.addEventListener('resize', e => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      if (ifDisplaySlider() && !row.classList.contains('init')) {
        dom.addClass(row, 'init');
        init();
      }
      if (!ifDisplaySlider() && row.classList.contains('init')) {
        dom.removeClass(row, 'init');
        destroySlider();
      }
    }, 20);
  })
}






const destroySlider = () => {
  hideArrows();
  sliders.forEach(slider => {
    if (slider.parentNode) {
      slider.parentNode.removeChild(slider);
    }
  });
  sliders.forEach(slider => {
    row.appendChild(slider);
  });
  setTimeout(() => {
    sliders.forEach(slider => {
      dom.addClass(slider, 'active');
    });
  }, 20);
}






const ifDisplaySlider = () => {
  return !(window.innerWidth < START_BREAKPOINT);
}






const showAllSliders = () => {
  sliders.forEach(slider => dom.addClass(slider, 'active'));
}





const hideAllSliders = () => {
  sliders.forEach(slider => dom.removeClss(slider, 'active'));
}





const init = () => {
  if (!ifDisplaySlider()) {
    showAllSliders();
    return;
  }
  displayArrows();
  removeSliders();
  setInitialCurrentSliders();

  if (!nextArrow.classList.contains('init')) {
    nextArrow.classList.add('init');
    nextArrow.addEventListener('click', e => changeNext(e));
  }

  if (!prevArrow.classList.contains('init')) {
    prevArrow.classList.add('init');
    prevArrow.addEventListener('click', e => changePrev(e));
  }
}





const changeNext = e => {
  if (animationTimer) return;
  let nextSliders = getNextSliders();
  if (!nextSliders.length) return;

  let slidersToChange = getSlidersToChange({ newSliders: nextSliders });
  setCurrentSliders({ slidersToChange, newSliders: nextSliders });
  replaceSliders({ slidersToChange, newSliders: nextSliders });
}






const changePrev = e => {
  if (animationTimer) return;
  let prevSliders = getPrevSliders();
  if (!prevSliders.length) return;

  let slidersToChange = getSlidersToChange({ newSliders: prevSliders });
  setCurrentSliders({ slidersToChange, newSliders: prevSliders });
  replaceSliders({ slidersToChange, newSliders: prevSliders });
}






const setCurrentSliders = ({ slidersToChange, newSliders }) => {
  let count = 0;
  currentSliders.forEach((slider, i) => {
    if (slidersToChange.indexOf(slider) > -1) {
      currentSliders[i] = newSliders[count];
      count++;
    }
  });
}






const getSlidersToChange = ({ newSliders }) => {
  let start = 0;
  let end = newSliders.length > currentSliders.length ? currentSliders.length : newSliders.length;
  start = currentSliders.length - newSliders.length;
  return currentSliders.slice(start);
}








const replaceSliders = ({ slidersToChange, newSliders }) => {
  slidersToChange.forEach(slider => {
    dom.removeClass(slider, 'active');
  });

  animationTimer = setTimeout(() => {
    slidersToChange.forEach((slider, i) => {
      slidersToChange[i].parentNode.replaceChild(newSliders[i], slidersToChange[i]);
    });
    setTimeout(() => {
      newSliders.forEach(slider => {
        dom.addClass(slider, 'active');
        animationTimer = false;
        initGtmOutboundLink(slider);
      });
    }, 20);
  }, 300);

}








const displayArrows = () => {
  dom.addClass(arrows, 'active');
}

const hideArrows = () => {
  dom.removeClass(arrows, 'active');
}





const removeSliders = () => {
  let maxSliders = getMaxSliders();
  for (let i = maxSliders; i < sliders.length; i++) {
    sliders[i].parentNode.removeChild(sliders[i]);
  }
}





const setInitialCurrentSliders = () => {
  currentSliders = [];
  let maxSliders = getMaxSliders();
  for (let i = 0; i < maxSliders; i++) {
    dom.addClass(sliders[i], 'active');
    currentLast = i;
    currentSliders.push(sliders[i]);
  }
}



const getNextSliders = () => {
  let next = [];
  let maxSliders = getMaxSliders();
  let start = currentLast + 1;
  // console.log(maxSliders, start, currentLast);
  for (let i = start; i < start + maxSliders; i++) {
    if (!sliders[i]) continue;
    next.push(sliders[i]);
    currentLast = i;
  }
  return next;
}





const getPrevSliders = () => {
  let prev = [];
  let currentIndexes = [];
  let maxSliders = getMaxSliders();

  currentSliders.forEach((slider) => {
    let index = sliders.indexOf(slider);
    currentIndexes.push(index);
  });

  if (currentIndexes[0] - maxSliders < 0 && currentIndexes[0] + maxSliders - 1 === currentIndexes[currentIndexes.length - 1]) return [];

  let start = -1;
  let countLeave = 1;

  for (let i = 0; i < currentIndexes.length; i++) {
    if (currentIndexes[i + 1] > currentIndexes[i] + 1) {
      start = currentIndexes[i] + 1;
      break;
    }
    countLeave++;
  }

  if (start === -1) {
    start = currentIndexes[0] - maxSliders > 0 ? currentIndexes[0] - maxSliders : 0;
  }


  let end = countLeave < maxSliders ? start + (maxSliders - countLeave) : start + maxSliders;
  for (let i = start; i < end; i++) {
    prev.push(sliders[i]);
    currentLast = i;
  }


  return prev;
}





const getMaxSliders = () => {
  return DISPLAY_SLIDERS;
}