export const loadClass = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;
  let els = dom.findAll('[data-load-class]', container);
  if (!els || !els.length) return;

  if (document.readyState === 'complete') {
    init({ els });
    return;
  } else {
    window.addEventListener('load', e => {
      init({ els });
    });
  }
}




const init = ({ els }) => {
  els.forEach(el => {
    let addClass = el.getAttribute('data-load-class');
    el.setAttribute('class', el.getAttribute('class') + ' ' + addClass);
  })
}