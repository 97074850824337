const MARGIN_COUNT = 100;
const MARGIN_SCROLL = 0;
const ACTIVE_CLASS = 'current-menu-item';




export const mainMenuSectionsNavigate = () => {

  let nav = dom.findFirst('.top-menu');
  if (!nav) return;

  let links = dom.findAll('.menu-item a', nav);
  if (!links || !links.length) return;


  links = getSectionsLinks({ links });
  if (!links) return;

  let sections = [];

  links.forEach((item, i) => {

    let href = item.getAttribute('href');
    let id = getIdFromHref({ href });
    if (!id) return;
    let section = dom.findFirst(id);

    if (section) {
      sections.push({ section, id, href, linkIndex: i });
    }

  });

  getOffsets({ sections });

  if (!sections.length) return;

  initActiveLinks({ sections, links });

  initNavigate({ sections, links });

  document.body.addEventListener('orientationchange', e => {
    getOffsets({ sections });
  });

  let resizeTimer;
  window.addEventListener('resize', e => {
    if (resizeTimer) {
      clearTimeout(resizeTimer);
    }
    resizeTimer = setTimeout(() => {
      getOffsets({ sections });
    }, 20);
  })
  // scrollIfHasHash({ sections, links });

}






const scrollIfHasHash = ({ sections, links }) => {
  let hash = window.location.hash;
  if (!hash) return;

  let total = sections.length;
  for (let i = 0; i < total; i++) {
    let item = sections[i];
    if (item.id === hash) {
      let target = item.section;
      let link = links[item.linkIndex];
      changeSection({ target, link });
      return;
    }
  }
}








const getSectionsLinks = ({ links }) => {
  let res = [];
  links.forEach(link => {
    let href = link.getAttribute('href');
    let id = getIdFromHref({ href });
    if (!id) return;
    res.push(link);
  });
  if (!res.length) return false;
  return res;
}








const getIdFromHref = ({ href }) => {
  if (!href) return false;
  if (!href.includes('#')) return false;

  let id = href.match(/[^#]*(#.*)/);

  if (!id[1]) return false;
  return id[1];
}








const initNavigate = ({ sections, links }) => {
  links.forEach((link, i) => {
    link.addEventListener('click', e => {
      e.preventDefault();
      let target = sections[i];
      changeSection({ target, link });
    });
  });
}






const changeSection = ({ target, link }) => {
  window.location.hash = target.id;
  window.scrollTo(0, target.top - MARGIN_SCROLL);
  dom.dispatch(dom.body, 'close-offcanvas');
  // setTimeout(() => {
    // dom.dispatch(dom.body, 'hide-fixed-nav');
  // }, 400);
}





const initActiveLinks = ({ sections, links }) => {
  let currentSection = getCurrentSection({ sections });
  setActiveLink({ sections, links, currentSection });
  let timerScroll;
  window.addEventListener('scroll', e => {
    if (timerScroll) {
      clearTimeout(timerScroll);
    }
    timerScroll = setTimeout(() => {
      let newSection = getCurrentSection({ sections });
      if (currentSection === newSection) return;
      currentSection = newSection;
      setActiveLink({ sections, links, currentSection });
    }, 20);
  });
}



const getOffsets = ({ sections }) => {
  sections.forEach(item => {
    let section = item.section;
    let top = section.getBoundingClientRect().top + window.pageYOffset;
    let bottom = top + section.offsetHeight;
    item.top = top;
    item.bottom = bottom;
  });
}



const setActiveLink = ({ sections, links, currentSection }) => {
  let currentLink = links[currentSection.linkIndex];
  removeAllActiveLinks({ links, currentLink });
  addActiveLink({ currentLink });
}




const addActiveLink = ({ currentLink }) => {
  dom.addClass(currentLink.parentNode, ACTIVE_CLASS);
}




const removeAllActiveLinks = ({ links, currentLink }) => {
  links.forEach(link => {
    if (link === currentLink) return;
    dom.removeClass(link.parentNode, ACTIVE_CLASS);
  })
}





const getCurrentSection = ({ sections }) => {
  let scroll = window.pageYOffset;
  // console.log(sections, scroll);
  for (let i = 0; i < sections.length; i++) {
    let item = sections[i];
    let maxScroll = sections[i + 1] ? sections[i + 1].top - MARGIN_COUNT : item.bottom - MARGIN_COUNT;
    if (scroll >= item.top - MARGIN_COUNT && scroll < maxScroll) {
      return item;
    }
  }


  let last = sections[sections.length - 1];

  if (scroll > last.bottom - MARGIN_COUNT) return last;

  let first = sections[0];

  if (scroll < first.top - MARGIN_COUNT) return first;
}