const CLASS_NAME = "play-video-on-hover";


export const playVideoOnHover = (wrap, sets = {}) => {
  if (!sets.items) {
    let container = dom.getContainer(wrap);
    let items = dom.findAll(`.${CLASS_NAME}`);

    if (!items || !items.length) return;
    initItems(items);
  } else {
    initItems(sets.items);
  }
}





const initItems = item => {
  item.forEach(item => {
    // let video = dom.findFirst('video', item);
    // if (!video) return;

    let poster = dom.findFirst('.video-poster', item);


    item.addEventListener('mouseenter', onMouseEnter.bind(null, item, poster));
    item.addEventListener('mouseleave', onMouseLeave.bind(null, item, poster));
  });
}



const onMouseEnter = (item, poster) => {
  let video = dom.findFirst('video', item);
  if (!video) return;

  dom.addClass(poster, 'hidden');
  dom.addClass(video, 'visible');
  video.play();
}


const onMouseLeave = (item, poster) => {
  let video = dom.findFirst('video', item);
  if (!video) return;

  dom.removeClass(poster, 'hidden');
  dom.removeClass(video, 'visible');
  video.pause();
}
