export const reviewsFancybox = () => {
  let items = dom.findAll('.review-link');
  if (!items || !items.length) return;
  
  items.forEach(item => {
    $(item).fancybox({
      infobar: false,
      caption: () => {
        let caption = $(item).next().clone(true);
        return caption;
      },
      buttons: ['close'],
      animationEffect: 'zoom',
      baseClass: 'review-slide'
    })
  })
};
