const GTM_EVENTS = {
  OUTBOUND_LINK: ({ href }) => {
    return {
      'event': 'autoEvent',
      'eventCategory': 'Interactions',
      'eventAction': 'Click outbound links',
      'eventLabel': href
    }
  },
  SIGN_IN: () => {
    return {
      'event': 'autoEvent',
      'eventCategory': 'Interactions',
      'eventAction': 'Button click - Sign in'
    }
  },
  OPEN_FORM: ({ section }) => {
    return {
      'event': 'autoEvent',
      'eventCategory': 'Interactions',
      'eventAction': 'Form open - Become Partner',
      'eventLabel': section
    }
  },
  FAQ: ({ isOpen, question }) => {
    let state = isOpen ? 'OPEN' : 'CLOSE';
    return {
      'event': 'autoEvent',
      'eventCategory': 'Interactions - faq',
      'eventAction': 'Click main page',
      'eventLabel': `${question} | ${state}`
    }
  },
  FAQ_OPEN_TAG: ({ tabName }) => {
    return {
      'event': 'autoEvent',
      'eventCategory': 'Interactions - faq',
      'eventAction': 'Click faq page',
      'eventLabel': tabName
    }
  },
  FAQ_OPEN_SPECIFIC_QUESTION: ({ tab, question, isOpen }) => {
    let state = isOpen ? 'OPEN' : 'CLOSE';
    return {
      'event': 'autoEvent',
      'eventCategory': 'Interactions - faq',
      'eventAction': 'Click faq page',
      'eventLabel': `${tab} | ${question} | ${state}`
    }
  },
  BECOME_PARTNER: ({ section }) => {
    return {
      'event': 'autoEvent',
      'eventCategory': 'CTA',
      'eventAction': 'Click button',
      'eventLabel': 'Become a partner',
      'eventLocation': section
    }
  },
  SEND_FORM: () => {
    return {
      'event': 'autoEvent',
      'eventCategory': 'CTA',
      'eventAction': 'Click button',
      'eventLabel': 'Send form'
    }
  },
  SUCCESSFUL_SUBMIT: () => {
    return {
      'event': 'autoEvent',
      'eventCategory': 'CTA',
      'eventAction': 'Success form submission'
    }
  },
  UNSUCCESSFUL_SUBMIT: ({ errors }) => {
    return {
      'event': 'autoEvent',
      'eventCategory': 'CTA',
      'eventAction': 'Failed form submission',
      'eventLabel': errors.join(' | ')
    }
  }
}


export const GTM = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  initGtmOutboundLink(wrap);
  initGtmSignin(wrap);
  initGtmOpenForm(wrap);
  initGtmFaq(wrap);
  initGtmOpenTab(wrap);
  initGtmFaqSpecificQuestion(wrap);
  initGtmBecomePartner(wrap);
  // initGtmSendForm(wrap);
}



export const addGtmEvent = ({ evName, data }) => {
  if (!GTM_EVENTS[evName]) {
    console.error(`${evName} doesn't exists!`);
    return;
  }
  if (!dataLayer) {
    console.error('dataLayer object is not declared!');
    return;
  }
  dataLayer.push(GTM_EVENTS[evName](data));
}




export const initGtmOutboundLink = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  let links = dom.findAll('a', container);
  if (!links || !links.length) return;

  links = links.filter(link => !link.href.includes(window.location.host) && link.href.includes('http'));
  if (!links || !links.length) return;

  let addLinks = dom.findAll('.gtm-outbound-link', container);
  if (addLinks && addLinks.length) {
    links = links.concat(addLinks);
  }

  links.forEach(link => {
    link.addEventListener('click', e => {
      addGtmEvent({ evName: 'OUTBOUND_LINK', data: { href: link.href } });
    });
  });
}





export const initGtmSignin = wrap => {
  initGtmLinks({
    wrap,
    className: 'gtm-sign-in',
    callBack: (link) => {
      addGtmEvent({ evName: 'SIGN_IN' });
    }
  });
}






export const initGtmOpenForm = wrap => {
  initGtmLinks({
    wrap,
    className: 'gtm-open-form',
    callBack: (link) => {
      addGtmEvent({ evName: 'OPEN_FORM', data: { section: link.dataset.gtmLabel } });
    }
  });

  let section = dom.findFirst('.become-partner-section');
  if (!section) return;

  let hasEvent = false;
  let timer;

  window.addEventListener('scroll', e => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (hasEvent) return;
      if (section.getBoundingClientRect().top < 0) {
        hasEvent = true;
        addGtmEvent({ evName: 'OPEN_FORM', data: { section: section.dataset.gtmLabel } });
      }
    }, 5);
  });

}




export const initGtmFaq = wrap => {
  initGtmLinks({
    wrap,
    className: 'gtm-faq',
    callBack: (link) => {
      let isOpen = link.classList.contains('active');
      addGtmEvent({ evName: 'FAQ', data: { question: link.dataset.gtmLabel, isOpen } });
    }
  });
}




export const initGtmOpenTab = wrap => {
  initGtmLinks({
    wrap,
    className: 'gtm-open-tab',
    callBack: (link) => {
      addGtmEvent({ evName: 'FAQ_OPEN_TAG', data: { tabName: link.dataset.gtmLabel } });
    }
  });
}






export const initGtmFaqSpecificQuestion = wrap => {
  initGtmLinks({
    wrap,
    className: 'gtm-faq-specific-question',
    callBack: (link) => {
      let isOpen = link.classList.contains('active');
      addGtmEvent({ evName: 'FAQ_OPEN_SPECIFIC_QUESTION', data: { tab: link.dataset.tab, question: link.dataset.question, isOpen } });
    }
  });
}






export const initGtmBecomePartner = wrap => {
  initGtmLinks({
    wrap,
    className: 'gtm-become-partner',
    callBack: (link) => {
      addGtmEvent({ evName: 'BECOME_PARTNER', data: { section: link.dataset.gtmLabel } });
    }
  });
}




export const initGtmSendForm = wrap => {
  addGtmEvent({ evName: 'SEND_FORM' });
}




export const initGtmSuccessfulSumit = () => {
  addGtmEvent({ evName: 'SUCCESSFUL_SUBMIT' });
}




export const initGtmUnsuccessfulSubmit = ({ errors }) => {
  addGtmEvent({ evName: 'UNSUCCESSFUL_SUBMIT', data: { errors } });
}





const initGtmLinks = ({ wrap, className, callBack }) => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  let links = dom.findAll(`.${className}`, container);
  if (!links || !links.length) return;

  links.forEach(link => link.addEventListener('click', e => callBack(link)));
}