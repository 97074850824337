import { addInputError, getHelpContainerMsgWrap, hideHelpContainer, showHelpContainer } from "./domFormValidate.js";
import { initGtmUnsuccessfulSubmit, initGtmSuccessfulSumit } from "../GTM";

const FORM_CLASS = 'ajax-form';
const INPUT_CLASS = 'input';
const INPUT_ERROR_CLASS = 'has-error';
const HELP_CONTAINER_CLASS = 'validate-form-msg';


const ADD_PRELOADER = ({ form }) => {
  return form.parentNode;
}


export const domAjaxForm = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  let forms = dom.findAll(`.${FORM_CLASS}`, container);
  if (!forms || !forms.length) return;

  forms.forEach(form => init(form));
}



const init = form => {
  form.addEventListener('submit', e => {
    e.preventDefault();
    if (!back_dates.re_key) {
      let data = new FormData(form);
      sendData(form, data);
    } else {
      sendRecaptchaData(form);
    }
  });
}



const sendRecaptchaData = form => {
  grecaptcha.ready(() => {
    grecaptcha.execute(back_dates.re_key, { action: 'submit' }).then(token => {
      let data = new FormData(form);
      data.append('recaptcha_token', token);
      sendData(form, data);
    });
  });
}


const sendData = (form, data) => {
  let url = form.getAttribute('action');
  sendFormData({ form, data, url })
    .then((response) => {
      validateResponse({ form, response });
    });
}




const sendFormData = ({ form, data, url }) => {
  return new Promise((resolve, reject) => {
    dom.ajax({
      url: url,
      data: data,
      preloader: ADD_PRELOADER({ form }),
      minTimeResponse: 300
    })
      .then((response) => resolve({ response, form }));
  });
}




const validateResponse = ({ form, response }) => {

  if (!response || !response.response) return;
  let res = JSON.parse(response.response);

  if (res.msg && typeof res.msg === 'string') res.msg = window.form_validate_msg[res.msg];

  if (res.type === 'success') {
    initGtmSuccessfulSumit();
  }

  if (res.type === 'error') {
    let errors = [];
    if (res.msg) {
      if (typeof res.msg === 'object' && res.length !== undefined) {
        errors = res.msg;
      } else {
        errors.push(res.msg);
      }
    }
    initGtmUnsuccessfulSubmit({ errors });
  }

  if (res.type === 'error' && res.inputs) {
    addResponseInputsError({ form, inputs: res.inputs });
  }

  if (res.type === 'error' && res.msg) {
    showResponseMsg({ msg: res.msg, form, isSuccess: false });
  }

  if (res.redirect) {
    window.location.href = res.redirect;
  }

  if (res.reload) {
    window.location.href = window.location.href;
  }

  if (res.type === 'success') {
    removeErrorInputs({ form });
    clearHelpContainer({ form });
    if (res.msg) {
      showResponseMsg({ msg: res.msg, form, isSuccess: true });
      // addSuccessHelpContainer({ form, msg: res.msg });
    }
  }

  if (res.clearValues) {
    clearValues({ response });
  }

  scrollToTopForm(form);

}



const scrollToTopForm = form => {
  window.scrollTo(0, form.getBoundingClientRect().top + window.pageYOffset - 100);
}



const addSuccessHelpContainer = ({ form, msg }) => {
  let formValidate = dom.findFirst('.validate-form-msg', form);
  if (!formValidate) return;
  let msgContainer = dom.findFirst('.msg-wrapper', formValidate);
  if (!msgContainer) return;
  msgContainer.innerHTML = msg;

  dom.addClass(formValidate, 'visible success');
}



const addResponseInputsError = ({ form, inputs, msg }) => {
  inputs.forEach(id => {
    let input = dom.findFirst(`#${id}`, form);
    if (!input) return;
    let helpContainer = form.parentNode.querySelector(`.${HELP_CONTAINER_CLASS}`);
    addInputError(input, 'ajax_response', helpContainer, msg);
  });
}





const removeErrorInputs = ({ form }) => {
  let inputs = dom.findAll(`.${INPUT_CLASS}`, form);
  inputs.forEach(input => dom.removeClass(input, INPUT_ERROR_CLASS));
}




const clearHelpContainer = ({ form }) => {
  let helpContainer = dom.findFirst(`.${HELP_CONTAINER_CLASS}`, form.parentNode);
  if (!helpContainer) return;
  let wrap = dom.findFirst('.msg-wrapper', helpContainer);
  if (wrap) {
    wrap.innerHTML = '';
  }
  dom.removeClass(helpContainer, 'visible success');
  // dom.addClass(helpContainer, 'dom-slide-up');
}



const clearValues = ({ form }) => {
  let inputs = dom.findAll(`.${INPUT_CLASS}`, form);
  if (!inputs || !inputs.length) return;
  inputs.forEach(input => {
    input.value = '';
  });
}




const showResponseMsg = ({ msg, form, isSuccess }) => {
  let helpContainer = dom.findFirst(`.${HELP_CONTAINER_CLASS}`, form.parentNode);

  if (!helpContainer) return;

  dom.removeClass(helpContainer, 'visible success');
  let wrap = getHelpContainerMsgWrap(helpContainer);
  if (!wrap) return;

  if (typeof msg === 'object' && msg.length !== undefined) {
    msg = msg.join('<br>', msg);
  }
  if (isSuccess) {
    wrap.innerHTML = msg;
    dom.addClass(helpContainer, 'visible success');
    return;
  }
  if (!dom.findFirst(`.ajax_response`, wrap)) {
    let row = dom.create('div', 'valid-msg ajax_response');
    row.innerHTML = msg;
    wrap.appendChild(row);
    showHelpContainer(helpContainer);
  }
}