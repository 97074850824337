export const navPreventHideOnDropdown = () => {
  let nav = dom.findFirst('.top-nav');
  if (!nav) return;


  let items = dom.findAll('.open-dropdown', nav);
  if (!items || !items.length) return;
  items.forEach(item => {
    item.addEventListener('click', e => {
      initItem(item);
    });
    document.body.addEventListener('click', e => {
      initItem(item);
    });
  });
}


const initItem = item => {
  if (item.classList.contains('active')) {
    dom.dispatch(dom.body, 'stop_hide_menu');
  } else {
    dom.dispatch(dom.body, 'start_hide_menu');
  }
}