const IMG_CLASS = 'img-cover';

export const domImageCover = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;
  let images = dom.findAll(`.${IMG_CLASS}`, container);
  if (!images || !images.length) return;

  images.forEach(img => {
    init({ img });
  });
}



const init = ({ img }) => {
  let parent = img.parentNode;
  if (!parent.tagName) return;
  let src = img.getAttribute('src');
  dom.css(parent, { 'background-image': `url('${src}')`, 'background-size': 'cover', 'background-repeat': 'no-repeat', 'background-position': 'center center' });
  img.parentNode.removeChild(img);
}