const ACTIVE_NAV_CLASS = 'active';
const ACTIVE_SLIDE_CLASS = 'active';
const ANIMATION_DURATION = 300;

let currentSlide = 0;

let current = 0;

let animationSlideHide;
let animationSlideShow;





export const advantagesSlider = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  let block = dom.findFirst('.section-advantages', container);
  if (!block) return;

  let navContainer = dom.findFirst('.slide-nav', block);
  if (!navContainer) return;

  let slidersContainer = dom.findFirst('.sliders-wrapper', block);
  if (!slidersContainer) return;

  let btns = dom.findAll('.slide-link', navContainer);
  if (!btns || !btns.length) return;

  let sliders = dom.findAll('.slide', slidersContainer);
  if (!sliders || !sliders.length) return;

  let prevArrow = dom.findFirst('.arrow-prev', block);
  let nextArrow = dom.findFirst('.arrow-next', block);

  let bgImg = block.querySelector('.bg-img');

  init({ btns, sliders, slidersContainer, navContainer, block: bgImg });
  initArrows({ btns, sliders, prevArrow, nextArrow, navContainer, slidersContainer, block: bgImg });
  initSwipe({ btns, sliders, prevArrow, nextArrow, navContainer, slidersContainer, block: bgImg });

  if (!bgImg) return;
  let timer;

  window.addEventListener('resize', e => {
    clearTimeout(timer);
    if (!bgImg.hasAttribute('style')) return;
    timer = setTimeout(() => {
      bgImg.removeAttribute('style');
    }, 5);
  });
}





const initSwipe = ({ btns, sliders, prevArrow, nextArrow, navContainer, slidersContainer, block }) => {

  let clientX;
  let clientY;

  slidersContainer.addEventListener('touchstart', e => {
    clientX = e.touches[0].clientX;
    clientY = e.touches[0].clientY;
  });

  slidersContainer.addEventListener('touchend', e => {
    let x = e.changedTouches[0].clientX;
    let y = e.changedTouches[0].clientY;

    let angle = Math.atan(Math.abs(clientY - y) / Math.abs(clientX - x));
    if (angle > 0.5 || Math.abs(clientX - x) < 15) return;

    let dir = e.changedTouches[0].clientX > clientX ? 'prev' : 'next';

    if (dir === 'next') {
      changeNext({ btns, sliders, prevArrow, nextArrow, navContainer, slidersContainer, block });
    }

    if (dir === 'prev') {
      changePrev({ btns, sliders, prevArrow, nextArrow, navContainer, slidersContainer, block });
    }
  });
}





const initArrows = ({ btns, sliders, prevArrow, nextArrow, navContainer, slidersContainer, block }) => {
  prevArrow.addEventListener('click', e => {
    e.preventDefault();
    changePrev({ btns, sliders, prevArrow, nextArrow, navContainer, slidersContainer, block });
  });
  nextArrow.addEventListener('click', e => {
    e.preventDefault();
    changeNext({ btns, sliders, prevArrow, nextArrow, navContainer, slidersContainer, block });
  });
}


const changePrev = ({ btns, sliders, prevArrow, nextArrow, navContainer, slidersContainer, block }) => {
  if (!sliders[current - 1]) {
    current = sliders.length - 1;
  } else {
    current = current - 1;
  }
  changeSlide({ sliders, navContainer, slidersContainer, block });
}


const changeNext = ({ btns, sliders, prevArrow, nextArrow, navContainer, slidersContainer, block }) => {
  if (!sliders[current + 1]) {
    current = 0;
  } else {
    current = current + 1;
  }
  changeSlide({ sliders, navContainer, slidersContainer, block });
}




const init = ({ btns, sliders, slidersContainer, navContainer, block }) => {

  btns.forEach((btn, i) => {
    btn.addEventListener('click', e => {
      e.preventDefault();
      if (animationSlideHide || animationSlideShow) {
        return;
      }
      current = i;
      changeSlide({ btn, sliders, navContainer, slidersContainer, block });

    });

  });
}






const changeSlide = ({ btn, sliders, navContainer, slidersContainer, block }) => {
  if (block) {
    dom.css(block, { 'height': `${block.offsetHeight}px` });
  }
  if (btn) {
    let currentLink = dom.findFirst(`.${ACTIVE_NAV_CLASS}`, navContainer);
    if (btn === currentLink) return;
    dom.removeClass(currentLink, ACTIVE_NAV_CLASS);
    dom.addClass(btn, ACTIVE_NAV_CLASS);
  }
  let currentSlide = dom.findFirst(`.${ACTIVE_SLIDE_CLASS}`, slidersContainer);
  // dom.css(currentSlide, { opacity: 0 });
  dom.addClass(currentSlide, 'hide-slide');
  dom.removeClass(currentSlide, 'animate');
  animationSlideHide = setTimeout(() => {
    dom.css(currentSlide, { display: 'none' });
    dom.removeClass(currentSlide, 'hide-slide');

    dom.css(sliders[current], { display: 'block' });

    animationSlideShow = setTimeout(() => {
      dom.addClass(sliders[current], 'animate');
      dom.removeClass(currentSlide, ACTIVE_SLIDE_CLASS);
      dom.addClass(sliders[current], ACTIVE_SLIDE_CLASS);

      currentSlide.removeAttribute('style');
      sliders[current].removeAttribute('style');
      animationSlideHide = null;
      animationSlideShow = null;
    }, 10);
  }, ANIMATION_DURATION);
}

