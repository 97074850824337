const ATTR = 'data-class-view';

let added = [];


export const domClassOnView = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  let items = dom.findAll(`[${ATTR}]`, container);

  if (!items || !items.length) return;

  items.forEach(item => {
    check({ item });
  });
  dom.onScroll(e => {
    items.forEach(item => {
      check({ item });
    });
  });
}




const check = ({ item }) => {
  if (added.indexOf(item) > -1) return;
  if (!dom.isInViewport(item)) return;

  added.push(item);

  let classToAdd = item.getAttribute(ATTR);
  if (!classToAdd) return;

  dom.addClass(item, classToAdd);
}