export const domLikeLink = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  let items = dom.findAll('[data-dom-href]', container);
  if (!items || !items.length) return;

  items.forEach(item => {
    item.addEventListener('click', e => {
      e.preventDefault();
      window.location.href = dom.getDomAttr(item, 'href');
    });
  });
}