let TRANSITION = 400;
let INTERVAL = 5000;


let section, slider, sliders, images = [], contents = [], bgImg;

let imgCol, contentCol;

let nextArrow, prevArrow;

let animationTimer, pauseInterval = false, progress;

let isHover = false;

let current = 0;






export const reviewsSlider = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  section = dom.findFirst('.section-review', container);
  if (!section) return;

  sliders = dom.findAll('.slider-item', section);
  if (!sliders || !sliders.length) return;

  nextArrow = dom.findFirst('.arrow-next', section);
  prevArrow = dom.findFirst('.arrow-prev', section);

  if (!nextArrow || !prevArrow) return;

  slider = dom.findFirst('.slider-reviews', section);
  if (!slider) return;

  progress = dom.findFirst('.progress__value', section);
  if (!progress) return;

  bgImg = section.querySelector('.bg-img');

  INTERVAL = slider.dataset.interval ? parseInt(slider.dataset.interval) : INTERVAL;
  getSlidersElements();

  init();

  initSwipe();

  if (!bgImg) return;

  let timer;
  window.addEventListener('resize', e => {
    clearTimeout(timer);
    if (!bgImg.hasAttribute('style')) return;
    timer = setTimeout(() => {
      bgImg.removeAttribute('style');
    }, 5);
  })
}



const initSwipe = () => {
  let clientX;
  let clientY;

  section.addEventListener('touchstart', e => {
    clientX = e.touches[0].clientX;
    clientY = e.touches[0].clientY;
  });

  section.addEventListener('touchend', e => {
    let x = e.changedTouches[0].clientX;
    let y = e.changedTouches[0].clientY;

    let angle = Math.atan(Math.abs(clientY - y) / Math.abs(clientX - x));
    if (angle > 0.5 || Math.abs(clientX - x) < 15) return;

    let dir = e.changedTouches[0].clientX > clientX ? 'prev' : 'next';

    if (dir === 'next') {
      changeSlider({ direction: 'next' });
    }

    if (dir === 'prev') {
      changeSlider({ direction: 'prev' });
    }
  });
}




const getSlidersElements = () => {
  contentCol = dom.findFirst('.content-col', sliders[0]);
  imgCol = dom.findFirst('.img-wrap', sliders[0]);
  sliders.forEach(slider => {
    images.push(dom.findFirst('.img-wrap img', slider));
    contents.push(dom.findFirst('.content-wrap', slider));
  });
}




const init = () => {
  nextArrow.addEventListener('click', e => changeSlider({ direction: 'next', e }));
  prevArrow.addEventListener('click', e => changeSlider({ direction: 'prev', e }));

  if (!INTERVAL) return;

  initProgress();

  initPauseInterval();
}





const initProgress = () => {

  let initialDash = parseInt(progress.getAttribute('stroke-dashoffset'));
  let steps = INTERVAL / 20;
  let step = initialDash / steps;


  setInterval(() => {

    if (animationTimer) {

      setTimeout(() => {
        progress.setAttribute('stroke-dashoffset', initialDash);
      }, 20);

      return;
    }

    if (pauseInterval) return;

    let dash = parseInt(progress.getAttribute('stroke-dashoffset'));

    if (dash === 0) {
      progress.setAttribute('stroke-dashoffset', initialDash);
      changeSlider({ direction: 'next' });
      return;
    }

    let next = dash - step > 0 ? dash - step : 0;
    progress.setAttribute('stroke-dashoffset', next);

  }, 20);



}




const initPauseInterval = () => {
  slider.addEventListener('mouseenter', e => {
    pauseInterval = true;
    isHover = true;
  });
  slider.addEventListener('mouseleave', e => {
    pauseInterval = false;
    isHover = false;
  });
  let scrollTime;
  window.addEventListener('scroll', e => {
    clearTimeout(scrollTime);

    scrollTime = setTimeout(() => {
      if (isInViewport(slider, -(slider.offsetHeight / 2))) {
        if (!isHover) pauseInterval = false;
      } else {
        if (!isHover) pauseInterval = true;
      }
    }, 5);
  });
}





const isInViewport = (el, margin = 0) => {
  let scroll = window.pageYOffset;
  let elTop = el.getBoundingClientRect().top;
  let elHeight = el.offsetHeight;
  let windowHeight = window.innerHeight;

  let top = elTop + scroll - margin - windowHeight;

  let bottom = elTop + scroll + elHeight + margin;

  return scroll >= top && scroll <= bottom;
}






const changeSlider = ({ direction, e }) => {
  e && e.preventDefault();

  if (animationTimer) return;

  if (bgImg) bgImg.style.height = bgImg.offsetHeight + 'px';

  hideCurrentElements();

  if (direction === 'next') {
    current = sliders[current + 1] ? current + 1 : 0;
  } else {
    current = sliders[current - 1] ? current - 1 : sliders.length - 1;
  }


  animationTimer = setTimeout(() => {
    clearContent();
    setCurrentContent();
    setTimeout(() => {
      showCurrentElements();
      animationTimer = null;
    }, 20);
  }, TRANSITION);

}


const clearContent = () => {
  let currentImg = dom.findFirst('img', imgCol);
  let currentContent = dom.findFirst('.content-wrap', contentCol);
  if (currentImg) currentImg.parentNode.removeChild(currentImg);
  if (currentContent) currentContent.parentNode.removeChild(currentContent);
}


const setCurrentContent = () => {
  let img = images[current];
  let content = contents[current];
  if (img) {
    imgCol.appendChild(img);
  }
  if (content) {
    contentCol.appendChild(content);
  }
}





const hideCurrentElements = () => {
  dom.addClass(imgCol, 'hide-img');
  dom.addClass(contentCol, 'hide-content');
}

const showCurrentElements = () => {
  dom.removeClass(imgCol, 'hide-img');
  dom.removeClass(contentCol, 'hide-content');
}