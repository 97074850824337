export const domSlideLink = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return false;
  let items = dom.findAll('.slide-link', container);
  if (!items || !items.length) return;

  items.forEach(item => {
    let target = dom.findFirst(item.getAttribute('href'));
    if (!target) return;
    setTargetStyle(target);
    let $target = $(target);
    item.addEventListener('click', e => {
      e.preventDefault();
      if (target.classList.contains('active')) {
        dom.removeClass(target, 'active');
        dom.removeClass(item, 'active');
        $target.slideUp({ duration: 200 });
      } else {
        dom.addClass(target, 'active');
        dom.addClass(item, 'active');
        $target.slideDown({ duration: 200 });
      }
      // $target.slideToggle({ duration: 200 });
    });
  })
}





const setTargetStyle = target => {
  dom.css(target, { display: 'none' });
  dom.removeClass(target, 'dom-slide-up');
}






const toggleTarget = item => {
  let target = dom.findFirst(item.getAttribute('href'));
  if (!target) return;
  let slide = dom.toggleSlide(target);
  if (slide === 'slideUp') {
    dom.addClass(item, 'target-hidden');
    dom.removeClass(item, 'target-visible');
  } else {
    dom.addClass(item, 'target-visible');
    dom.removeClass(item, 'target-hidden');
  }
}