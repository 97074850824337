import "./css/domCarouselCore.less";
import "./css/domCarouselTheme.less";

import Glide from '@glidejs/glide'

const SLIDER_CLASS = 'dom-overflow-slider';


export const domOverflowSlider = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  let sliders = dom.findAll(`.${SLIDER_CLASS}`, container);

  if (!sliders || !sliders.length) return;


  sliders.forEach(slider => {
    initSlider(slider);
  });
}





const initSlider = slider => {

  let sets = dom.getDomAttr(slider, 'carousel-sets');
  sets = sets ? JSON.parse(sets) : {};

  let isOverflow = dom.isOverflow(slider).x;

  let glide = false;

  if (isOverflow) {
    glide = initGlide({ slider, sets });
  }

  window.addEventListener('resize', e => {

    let nowOverflow = dom.isOverflow(slider).x;
    if (isOverflow !== nowOverflow) {
      isOverflow = nowOverflow;
      if (isOverflow) {
        glide = initGlide({ slider, sets });
      } else {
        destroyGlide({ glide, slider });
      }
    }

  });

}



const initGlide = ({ slider, sets }) => {
  let controls = dom.findFirst('[data-glide-el=controls]', slider.parentNode);
  if (controls) {
    dom.addClass(controls, 'active');
  }
  return new Glide(slider, sets).mount();
}


const destroyGlide = ({ glide, slider }) => {
  if (glide) {
    glide.destroy();
  }
  let controls = dom.findFirst('[data-glide-el=controls]', slider.parentNode);
  if (controls) {
    dom.removeClass(controls, 'active');
  }
  dom.removeClass(slider, 'glide--swipeable');
}